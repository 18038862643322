/* Navbar mobile */
@media screen and (max-width: 1366px) {
  .navbar .navbar-brand img {
    width: 62px !important;
    height: 62px !important;
  }
  .navbar-brand {
    font-size: 1.1rem !important;
  }
  .navbar {
    min-height: 80px !important;
  }
  .navbar .navbar-nav .nav-link:not(.btn) {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
  }
  .section-services .title .h1-seo {
    padding-bottom: 0 !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 990px) {
  .navbar .navbar-brand img {
    width: 45px !important;
    height: 45px !important;
  }
  .navbar-brand {
    font-size: 1rem !important;
  }
}

@media screen and (min-width: 1360px) and (max-width: 1460px) {
  .navbar .navbar-brand img {
    width: 50px !important;
    height: 50px !important;
  }
  .navbar-brand {
    font-size: 1.1rem !important;
  }
  .navbar .navbar-nav .nav-link:not(.btn) {
    font-size: 0.8rem !important;
    font-weight: 500 !important;
  }
  .quote-main {
    position: relative;
  }
  .header-quote:first-child {
    font-size: 23px;
    position: absolute;
    left: 500px;
    height: auto;
    line-height: 1.5;
    width: 450px;
  }
  .header-quote:last-child {
    font-size: 23px;
    position: absolute;
    left: 400px;
    top: 50px;
    height: auto;
    line-height: 1.5;
    width: 550px;
  }
}

@media screen and (min-width: 1050px) {
  .quote-wrapper {
    margin: 0;
    padding: 0;
    visibility: hidden;
  }
  .quote-main .landing-list {
    line-height: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .hero .quote-main {
    visibility: hidden;
  }
  .navbar .navbar-brand {
    font-size: 1.5rem !important;
  }
  .navbar .navbar-brand img {
    max-width: 55px !important;
    max-height: 55px !important;
  }
  .navbar-toggler .navbar-toggler-icon {
    font-size: 25px;
  }
  .hero .quote-wrapper h6 {
    font-size: 1.2rem !important;
  }
  .hero .quote-wrapper {
    margin-top: 120px !important;
  }
  .section-contact-us .contact-map {
    margin-right: 50px;
  }
}

@media screen and (max-width: 900px) {
  .hero .container-fluid {
    margin-bottom: 100px;
  }
  .header-quote {
    font-size: 0.9rem;
  }
  .quote-wrapper {
    margin-top: -50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 800px) {
  .navbar .navbar-brand {
    font-size: 1.3rem !important;
  }
  .navbar .navbar-brand img {
    max-width: 45px !important;
    max-height: 45px !important;
  }
  .navbar-toggler .navbar-toggler-icon {
    font-size: 20px;
  }
  .header-quote {
    font-size: 0.9rem;
  }
  .hero .quote-wrapper {
    margin-top: 80px !important;
  }
  .section-certificates .certificates-ul {
    margin-right: 0px !important;
    margin-left: -60px;
  }
  .section-certificates .certificates-ul li {
    min-width: 220px !important;
  }
  .section-contact-us .contact-map {
    margin-right: 50px;
  }
  .section-services .carousel-indicators-list li {
    margin-right: 70px !important;
  }
}

@media screen and (max-width: 414px) {
  .navbar .navbar-brand {
    font-size: 0.92rem !important;
    color: white;
  }
  .navbar .navbar-brand img {
    max-width: 38px !important;
    max-height: 38px !important;
  }
  .navbar-toggler .navbar-toggler-icon {
    font-size: 14px;
  }
  .section-contact-us .contact-map {
    margin-right: 1.8rem;
  }
}

@media screen and (max-width: 411px) {
  .navbar .navbar-brand {
    font-size: 0.9rem !important;
  }
  .navbar .navbar-brand img {
    max-width: 35px !important;
    max-height: 35px !important;
  }
  .navbar {
    min-height: 40px !important;
  }
  .section-contact-us .contact-map {
    margin-right: 1.8rem;
  }
}

@media screen and (max-width: 375px) {
  .navbar .navbar-brand {
    font-size: 0.8rem !important;
    color: white;
  }
}

@media screen and (max-width: 360px) {
  .navbar .navbar-brand {
    font-size: 0.78rem !important;
    color: white;
  }
  .navbar .navbar-brand img {
    width: 27px !important;
    height: 27px !important;
  }
  .section-certificates .certificates-ul {
    margin-left: -30px !important;
    margin-right: 10px !important;
  }
  .section-about-us .section-story-overview .image-container.third-image {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 0;
    height: 335px;
    width: 300px;
    background-position: center center;
    background-size: cover;
  }
  .about-us-ul {
    margin-left: -40px;
  }
  .section-team .team-player p {
    font-size: 15px;
  }
  .section-team .partner-align {
    height: 220px;
  }
  .section-transparency .transparency-ul li {
    display: flex;
    flex-basis: 100%;
  }
  .section-transparency .transparency-ul li a {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .navbar-toggler .navbar-toggler-icon {
    font-size: 10px;
  }
  .section-certificates .certificates-ul {
    margin: 0 0 0 -35px !important;
  }
  .section-transparency .h1-seo {
    font-size: 2.3rem;
  }
  .navbar .navbar-brand {
    font-size: 0.71rem !important;
  }
  .navbar .navbar-brand img {
    width: 23px !important;
    height: 23px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 414px) {
  .carousel-inner .carousel-caption p {
    font-size: 6.5px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .carousel-inner .carousel-caption h4 {
    font-size: 11px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .carousel-inner .carousel-caption.acc {
    margin-bottom: -10px !important;
  }
  .carousel-inner .carousel-caption .acc-h4 {
    margin-bottom: -20px !important;
  }
  .carousel-inner .carousel-caption.rev {
    margin-bottom: -10px !important;
  }
  .carousel-inner .carousel-caption .rev-h4 {
    margin-bottom: -10px !important;
  }
  .carousel-inner .carousel-caption.reg {
    margin-bottom: -10px !important;
  }
  .carousel-inner .carousel-caption .reg-h4 {
    margin-bottom: -10px !important;
  }
  .carousel-inner .carousel-caption.bus p {
    font-size: 6px !important;
  }
  .carousel-inner .carousel-caption.bus h4 {
    font-size: 10px !important;
  }
  .carousel-inner .carousel-caption.bus {
    margin-bottom: -10px !important;
  }
  .carousel-inner .carousel-caption .bus-h4 {
    margin-bottom: -10px !important;
  }
  .carousel-inner .carousel-caption.appr {
    margin-bottom: 40px !important;
  }
  .carousel-inner .carousel-caption .appr-h4 {
    margin-bottom: -20px !important;
  }
  .carousel-inner .carousel-caption.inve {
    margin-bottom: 22px !important;
  }
  .carousel-inner .carousel-caption .inve-h4 {
    margin-bottom: -20px !important;
  }
  .carousel-inner .carousel-caption.brok {
    margin-bottom: 22px !important;
  }
  .carousel-inner .carousel-caption .brok-h4 {
    margin-bottom: -20px !important;
  }
  .section-services .carousel-indicators-list {
    margin: 0;
    padding: 0;
    margin-top: 5px;
  }
  .section-services .carousel-indicators-list li {
    min-width: 270px;
    margin-right: 0 !important;
  }
  .section-certificates .certificates-ul {
    margin-left: -30px !important;
    margin-right: 10px !important;
  }
  .section-about-us .section-story-overview .image-container.third-image {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 0;
    height: 335px;
    width: 300px;
    background-position: center center;
    background-size: cover;
  }
  .about-us-ul {
    margin-left: -40px;
  }
}

.navbar {
  font-size: 1.15rem;
  max-height: 75px;
}

.navbar .navbar-brand {
  font-size: 1.4rem;
}

.navbar .navbar-brand:hover {
  color: white;
  text-decoration: none;
}

.navbar .navbar-brand img {
  width: 65px;
  height: 65px;
}

/* Flag buttons */
.navbar .navbar-nav button {
  margin-top: 6px;
}

.navbar {
  background-color: rgba(247, 110, 156, 1);
}

.navbar a,
.navbar .nav-link a {
  color: white !important;
  cursor: pointer;
}

.navbar .nav-item button:focus {
  background-color: rgb(126, 5, 126, 0.2);
  box-shadow: none;
}

.navbar .navbar-nav .nav-item .nav-link:not(.btn):hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.1875rem;
  text-decoration: none;
  color: white;
}

.cover {
  background: no-repeat top/cover;
  background-size: cover;
  overflow: hidden;
}

.section-contact-us .contact-map {
  min-height: 500px;
  max-width: 800px;
}

.landing-list {
  text-align: left;
}

.landing-list li {
  font-size: 1.1rem;
  background-color: rgba(247, 110, 156, 0.5) !important;
  color: #fff;
}
.landing-list li.disabled {
  color: black;
  font-size: 1.4em;
  text-align: center;
}

.list-group.landing-list li:hover {
  background-color: rgba(186, 186, 186, 0.8) !important;
  cursor: pointer;
}

section {
  margin-bottom: 40px;
  padding-bottom: 70px;
}

.hero .container-fluid {
  margin-bottom: 240px;
}

.header-quote {
  background-color: rgba(247, 110, 156, 0.5) !important;
  border-radius: 50px;
  color: white;
  text-align: center;
}

.hero-wrap {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #fff;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .image-container .image-left {
    margin-right: 100px;
  }
}

@media screen and (min-width: 768px) {
  .image-container.image-right {
    top: 80px;
    margin-left: -100px;
    margin-bottom: 130px;
  }
}

@media screen and (min-width: 768px) {
  .image-container.image-left {
    margin-right: -100px;
  }
}

@media screen and (max-width: 480px) {
  .about-us-ul {
    list-style: none;
  }
}

.section-story-overview {
  padding: 50px 0;
  box-sizing: border-box;
}

.section-story-overview .image-container.image-left {
  z-index: 1;
}

.section-story-overview .image-container {
  height: 335px;
  position: relative;
  background-position: center center;
  background-size: cover;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
}

.section-story-overview p.blockquote {
  width: 200px;
  min-height: 180px;
  text-align: left;
  position: absolute;
  top: 376px;
  right: 155px;
  z-index: 0;
}

.image-container {
  z-index: 1;
}

.blockquote.blockquote-pink {
  border-color: #f76e9c;
  color: #f76e9c;
}

.blockquote {
  border-left: none;
  border: 1px solid #888888;
  padding: 20px;
  font-size: 1.1em;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.section-story-overview p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.section-story-overview .image-container.image-right {
  z-index: 2;
}

.section p {
  line-height: 1.61em;
  font-weight: 300;
  font-size: 1.2em;
  margin-top: 0;
  margin-bottom: 1rem;
}

.about-us-ul li {
  font-size: 1.1rem;
  font-weight: 350;
}

.title {
  font-weight: 700;
  padding-top: 30px;
}

.h1-seo {
  font-size: 3.5em;
  line-height: 1.15;
  margin-bottom: 30px;
  font-weight: 400;
  margin-top: 0;
}

.description {
  color: #9a9a9a;
  font-weight: 300;
  font-size: 1.3em;
  line-height: 1.4em;
  margin-bottom: 15px;
  margin-top: 0;
}

.bg-pink {
  background-color: rgba(247, 110, 156, 0.3) !important;
}

.team-rounded-circle {
  border-radius: 50% !important;
}

.team-img-fluid {
  max-width: 100%;
  height: auto;
}

.team-player img {
  vertical-align: middle;
  border-style: none;
}

.landing-page .section-team .team .team-player img {
  max-width: 100px;
}

.section {
  padding-bottom: 70px;
}

.img-raised {
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}

.rounded-circle {
  border-radius: 50% !important;
}

.img-fluid {
  height: auto;
}

.btn.btn-icon {
  height: 2.375rem;
  min-width: 2.375rem;
  width: 2.375rem;
  padding: 0;
  font-size: 0.9375rem;
  overflow: hidden;
  position: relative;
  line-height: normal;
}

.btn-round {
  border-width: 1px;
  border-radius: 30px !important;
  padding: 11px 23px;
}

/* ## NAVBAR ## */
.navbar.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  color: #ffffff;
  padding-top: 20px !important;
}

.navbar {
  font-family: 'Montserrat', sans-serif;
}

/* Navbar list */
.navbar .navbar-nav .nav-item .nav-link:not(.btn):hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.1875rem;
}

/* #### NAVBAR LIST ITEMS #### */
.navbar .navbar-nav .nav-link:not(.btn) {
  text-transform: uppercase;
  font-size: 0.9rem;
  line-height: 2rem;
  margin-right: 5px;
  text-align: center;
  word-wrap: break-word;
  white-space: nowrap;
  font-weight: 550;
}

.nav-pills .nav-item .nav-link {
  background-color: rgba(222, 222, 222, 0.3);
  font-weight: 400;
  color: #444;
}

.nav-link:hover {
  text-decoration: none;
  cursor: pointer;
}

.nav-pills .nav-link,
.nav-item .nav-link,
.navbar,
.nav-tabs .nav-link,
.tag,
.tag [data-role='remove'] {
  transition: all 300ms ease 0s;
}

.nav-link {
  display: block;
}

.btn-lg {
  font-size: 1em;
  padding: 15px 48px;
}

.btn-pink {
  background-color: #f76e9c;
  color: #ffffff;
}

/* !!!! IMPORTANT for certificate buttons */
.btn {
  font-weight: 400px;
  line-height: 1.35em;
  margin: 10px 1px;
  border: none;
  user-select: none;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.team-player button {
  background-color: #f76e9c;
  border: none;
}

button,
input {
  overflow: visible;
}

button {
  font: 400 13.3333px Arial;
  align-items: flex-start;
  text-indent: 0px;
  text-shadow: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
}

.category {
  font-weight: 700;
  color: #9a9a9a;
}

.category p {
  line-height: 1.61em;
  font-size: 1.2em;
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

[data-background-color]:not([data-background-color='gray']) {
  color: #ffffff;
}

.card[data-background-color='pink'] {
  background-color: rgba(246, 86, 140, 0.8);
}

.card.card-news:hover {
  box-shadow: 0 0 12px rgba(33, 33, 33, 0.2);
}

.card {
  border: 0;
  border-radius: 0.1875rem;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.card .card-header {
  border-bottom: 0;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}

.card-header {
  margin-bottom: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-footer {
  font-size: 16px;
}

.card .nav-tabs {
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
}

.nav-tabs {
  border: 0;
  padding: 15px 0.7rem;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30;
  list-style: none;
}

.nav-tabs > .nav-item > .nav-link:hover {
  background-color: transparent;
}

.nav-tabs > .nav-item > .nav-link {
  color: #888888;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
}

.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  border: none;
  color: #ffffff;
}

.nav-tabs.nav-tabs-neutral > .nav-item > .nav-link {
  color: #ffffff;
}

.section-services button {
  background-color: #f76e9c;
}

.section.section-services {
  margin-top: -50px;
}

.section-news ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.landing-page .section-contact-us .input-group,
.landing-page .section-contact-us .send-button,
.landing-page .section-contact-us .textarea-container {
  padding: 0 40px;
}

.input-group {
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group input,
.input-group-prepend span {
  border-radius: 100px;
}

textarea.form-control {
  max-width: 100%;
  max-height: 80px;
  padding: 10px 10px 0 0;
  resize: none;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  border-radius: 0;
  line-height: 2;
  height: auto;
}

.send-button {
  padding: 0 40px;
}

.landing-page .section-contact-us a.btn {
  margin-top: 35px;
}

.btn-lg {
  font-size: 1em;
  padding: 15px 48px;
}

.btn-block {
  display: block;
  width: 100%;
}

[data-background-color]:not([data-background-color='gray']) {
  color: #ffffff;
}

.section-download {
  padding-top: 130px;
}

[data-background-color='black'] {
  background-color: #2c2c2c;
}

.sharing-area {
  margin-top: 80px;
}

.btn.btn-icon.btn-lg,
.navbar .navbar-nav > a.btn.btn-icon.btn-lg {
  height: 3.6rem;
  min-width: 3.6rem;
  width: 3.6rem;
  background-color: #ffffff;
}

.btn.btn-icon.btn-footer,
.navbar .navbar-nav > a.btn.btn-icon {
  padding: 0;
  font-size: 2.5rem;
  overflow: hidden;
  position: relative;
  line-height: normal;
  /* border-radius: 50px; */
}

.btn-twitter,
.btn-twitter:hover,
.btn-twitterfocus,
.btn-twitter:active,
.btn-twitter:active:focus {
  color: #55acee !important;
}

.btn-neutral {
  background-color: #ffffff;
}

.footer {
  padding: 24px 0;
}

.footer .container a {
  margin-bottom: 0;
  padding: 0;
  display: inline-block;
}

@media screen and (min-width: 992px) {
  .footer .copyright {
    float: right;
  }
}

.footer .copyright {
  font-size: 0.8571em;
  text-decoration: none;
  color: #ffffff;
}

.our-team-g {
  text-align: left;
  margin-left: 20px;
}

.our-team-g a {
  color: rgba(247, 110, 156, 1) !important;
}

.btn.our-team-a {
  background-color: rgba(247, 110, 156, 1) !important;
  border: none;
  color: white;
  text-decoration: none;
}

.team-player {
  font-size: 0.9rem !important;
  font-weight: 350;
  margin-bottom: 20px;
}

.team-player .our-team-category {
  color: rgba(247, 110, 156, 1) !important;
  font-size: 1.1rem;
  font-weight: 350;
  color: grey;
}

.mail-icon .mail-svg {
  font-size: 20px;
  margin-top: 7px;
}

.team-player .mail-icon {
  color: #ffffff;
  background-color: rgba(247, 110, 156, 1) !important;
}

.service-pills button {
  font-size: 18px;
}
@media screen and (max-width: 480px) {
  .h1-seo {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 763px) {
  .trans-ul-1 {
    margin-top: -15px;
  }
}

.certificates-ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 30px;
}

.certificates-ul li {
  min-width: 270px;
}

.certificates-ul li .cert-div p {
  margin-left: 15px;
  font-size: 0.9rem;
  margin-top: -10px;
  color: black;
}

.certificates-ul li .cert-div {
  border-radius: 1rem;
  background-color: rgba(247, 110, 156, 1) !important;
  border: none;
  border-radius: 1rem;
}

.certificates-ul li a {
  background-color: rgba(247, 110, 156, 1) !important;
  border: none;
}

.certificates-ul li .cert-small {
  background-color: #e0e0e0;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.section-transparency .transparency-ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 30px;
}

.transparency-ul li .cert-div {
  border-radius: 1rem;
  background-color: rgba(247, 110, 156, 1) !important;
  border: none;
  max-width: 340px;
}

.transparency-ul li .cert-div .btn {
  line-height: 0.7rem;
}

.transparency-ul li .cert-div p {
  margin-left: 15px;
  font-size: 0.9rem;
  margin-top: -10px;
  color: black;
}

.transparency-ul li {
  width: 50%;
  margin-bottom: 10px;
}

.transparency-ul li a {
  background-color: rgba(247, 110, 156, 1) !important;
  border: none;
  border-radius: 1rem;
}

.transparency-drop {
  text-align: center;
  font-size: 20px;
}

.transparency-drop:hover li svg {
  color: rgba(247, 110, 156, 0.8) !important;
}

#servicesCarousel img {
  min-width: 100%;
  -webkit-filter: brightness(50%);
  -moz-filter: brightness(50%);
  filter: url(#brightness);
  filter: brightness(50%);
}

.carousel-indicators-list {
  margin-top: 30px;
}

.carousel-indicators-list li {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  background-color: rgba(247, 110, 156, 1) !important;
  min-height: 38px;
  min-width: 300px;
  margin-bottom: 8px;
  font-size: 20px;
  border-radius: 30px;
  cursor: pointer;
  text-overflow: visible;
}

#servicesCarousel .carousel-inner h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  line-height: 4rem;
  text-align: center;
}

#servicesCarousel .carousel-item p {
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
}

.btn.btn-pink {
  color: #fff;
  background-color: rgba(247, 110, 156, 1) !important;
  margin-left: 22px;
}

.btn.btn-pink:hover {
  color: #fff;
  background-color: #c82333 !important;
  border-color: #bd2130;
}

.btn-pink.focus,
.btn-pink:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.section-news {
  font-family: 'Montserrat', sans-serif;
}

.btn.btn-secondary {
  background-color: transparent;
  border: none;
  font-size: 21px;
}

.btn.btn-secondary:hover {
  background-color: #c82333 !important;
  border-color: #bd2130;
}

.btn.btn-warning {
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.btn.btn-warning:hover {
  background-color: #c82333 !important;
  border-color: #bd2130;
  border: 1px solid darkviolet;
}

.news-group {
  margin-left: -15px;
}

.scroll-button {
  opacity: 0.6;
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 5px;
  border: none;
}

.scroll-button:hover {
  opacity: 1;
}

.section-sign-in .btn-login:hover {
  background-color: rgb(233, 1, 59) !important;
}

.navbar .collapse .navbar-nav .nav-item a.paint {
  border-bottom: 2px solid rgb(218, 233, 1);
  transition: all 0.1s ease;
}

.partner-align {
  height: 14em;
}

.success-toast {
  background-color: #5cb85c;
}

.danger-toast {
  background-color: #d9534f;
}

.info-toast {
  background-color: #5bc0de;
}

.warning-toast {
  background-color: #f0ad4e;
}
